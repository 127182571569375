import React, {memo, useEffect, useRef, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {connect} from "react-redux";

import CommentToolbox from "../toolbox/CommentToolbox";
import RulerToolbox from "../toolbox/RulerToolbox";
import StickyNotes from "../toolbox/StickyNotes";
import DrawerToolbox from "../toolbox/DrawerToolbox";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import EraserToolbox from "../toolbox/EraserToolbox";
import EraserMockupComponent from "../toolbox/EraserToolbox/EraserMockupComponent";
import DeleteToolbox from "../toolbox/DeleteToolbox";
import UndoToolbox from "../toolbox/UndoRedoToolbox/UndoToolbox";
import RedoToolbox from "../toolbox/UndoRedoToolbox/RedoToolbox";
import MapboxDrawToolbox from "../toolbox/MapboxDrawToolbox";
import TextToolBox from "../toolbox/TextToolBox";

import {
    getMapStyledId,
    getPainterGeoJsonDataST,
    getPainterStartData,
    getScreenShootButtonStateST,
} from "../../store/selectors";

import {MAP_TOOLBOX_KEYS} from "../mapToolbox";
import "./style.css";

const PaintBar = (props) => {

    const {
        getPainterGeoJsonDataST,
        getMapStyledId,
        map,
        painterStart,
        getScreenShootButtonStateST,
        tools,
        globalLoading
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const hasEraser = tools?.[MAP_TOOLBOX_KEYS.ERASER];
    const hasRuler = tools?.[MAP_TOOLBOX_KEYS.RULER];
    const hasDrawer = tools?.[MAP_TOOLBOX_KEYS.DRAWER];
    const hasUndo = tools?.[MAP_TOOLBOX_KEYS.UNDO];
    const hasRedo = tools?.[MAP_TOOLBOX_KEYS.REDO];
    const hasDelete = tools?.[MAP_TOOLBOX_KEYS.DELETE];
    const hasLineDraw = tools?.[MAP_TOOLBOX_KEYS.LINE_DRAW];
    const hasPolygonDraw = tools?.[MAP_TOOLBOX_KEYS.POLYGON_DRAW];
    const hasCommentBox = tools?.[MAP_TOOLBOX_KEYS.COMMENT_BOX];
    const hasStickyNotes = tools?.[MAP_TOOLBOX_KEYS.STICKY_NOTES];
    const hasTextBox = tools?.[MAP_TOOLBOX_KEYS.TEXT_BOX];

    const [isScreenShot, setIsScreenShot] = useState(query.get("screenShot"));

    const drawerGeoJsonRef = useRef(null);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setIsScreenShot(() => query.get("screenShot"))

        if (query.get("loading") === 'true') {
            removeAllLayersByClick()
        }
    }, [searchParams])

    const removeAllLayersByClick = (e) => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('loading');
        setSearchParams(updatedSearchParams);
    };

    return (
        <div
            className="paintBar_container"
            style={{display: painterStart ? "flex" : "none"}}
        >
            <div id={"screen-shot-icon"} className="lastToolboxBtn">
                {painterStart && !isScreenShot && <MapScreenShotButton map={map}/>}
                {painterStart && isScreenShot && (
                    <MapScreenShotButton map={map} disable={getScreenShootButtonStateST}/>
                )}
            </div>
            {
                hasDelete && (<DeleteToolbox map={map}/>)
            }
            {
                hasUndo && (<UndoToolbox map={map}/>)
            }
            {
                hasRedo && (<RedoToolbox map={map}/>)
            }
            {
                hasRuler && (<RulerToolbox isScreenShot={isScreenShot}
                                           hasLineOrPolygonDraw={hasLineDraw || hasPolygonDraw}
                                           map={map}/>)
            }
            {
                hasEraser && (hasDrawer ? <EraserToolbox map={map}/> : <EraserMockupComponent map={map}/>)
            }
            {
                hasDrawer && (<DrawerToolbox map={map}
                                             drawerGeoJsonRef={drawerGeoJsonRef}
                                             isScreenShot={isScreenShot}
                                             hasLineOrPolygonDraw={hasLineDraw || hasPolygonDraw} />)
            }
            {
                hasStickyNotes && (<StickyNotes map={map} />)
            }
            {
                hasTextBox && (<TextToolBox map={map} />)
            }
            {
                hasCommentBox && (<CommentToolbox map={map}/>)
            }
            {
                (hasLineDraw || hasPolygonDraw) && (<MapboxDrawToolbox
                    globalLoading={globalLoading}
                    hasLineDraw={hasLineDraw}
                    hasPolygonDraw={hasPolygonDraw}
                    map={map}
                    isScreenshot={isScreenShot}/>)
            }
        </div>
    );
};

const mapStateTopProps = (state) => ({
    painterStart: getPainterStartData(state),
    getScreenShootButtonStateST: getScreenShootButtonStateST(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getMapStyledId: getMapStyledId(state),
});

const mapDispatchToProps = {};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(PaintBar));
